export default class SparkConfig {
    defaultParallelism: number;
    executorMemory: number;
    executorInstances: number;
    driverCores: number;
    executorCores: number;
    driverMemory: number;
    driverMaxResultSize: number;
    driverMemoryOverhead: number;
    executorMemoryOverhead: number;
    dynamicAllocation: boolean;
    adaptiveQuery: boolean;
    constructor(
        defaultParallelism = 0,
        executorMemory = 0,
        executorInstances = 0,
        driverCores = 1,
        executorCores = 5,
        driverMemory = 0,
        driverMaxResultSize = 0,
        driverMemoryOverhead = 0,
        executorMemoryOverhead = 0,
        dynamicAllocation = false,
        adaptiveQuery = true
    ) {
        this.defaultParallelism = defaultParallelism;
        this.executorMemory = executorMemory;
        this.executorInstances = executorInstances;
        this.driverCores = driverCores;
        this.executorCores = executorCores;
        this.driverMemory = driverMemory;
        this.driverMaxResultSize = driverMaxResultSize;
        this.driverMemoryOverhead = driverMemoryOverhead;
        this.executorMemoryOverhead = executorMemoryOverhead;
        this.dynamicAllocation = dynamicAllocation;
        this.adaptiveQuery = adaptiveQuery;
    }
}

export const SparkConfigMap: { [key: string]: string } = {
    defaultParallelism: 'spark.default.parallelism',
    executorMemory: 'spark.executor.memory',
    executorInstances: 'spark.executor.instances',
    driverCores: 'spark.driver.cores',
    executorCores: 'spark.executor.cores',
    driverMemory: 'spark.driver.memory',
    driverMaxResultSize: 'spark.driver.maxResultSize',
    driverMemoryOverhead: 'spark.driver.memoryOverhead',
    executorMemoryOverhead: 'spark.executor.memoryOverhead',
    dynamicAllocation: 'spark.dynamicAllocation.enabled',
    adaptiveQuery: 'spark.sql.adaptive.enabled',
}

export const UnitConfigMap: { [key: string]: string } = {
    defaultParallelism: "",
    executorMemory: "g",
    executorInstances: "",
    driverCores: "",
    executorCores: "",
    driverMemory: "g",
    driverMaxResultSize: "g",
    driverMemoryOverhead: "m",
    executorMemoryOverhead: "m",
    dynamicAllocation: "",
    adaptiveQuery: "",
}