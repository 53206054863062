



























import { ClusterParamValidation } from "@/models/ClusterParams";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class NumberInput extends Vue {
  @Prop() model!: ClusterParamValidation;
  @Prop({ default: "" }) label!: string;
  @Prop() errorText?: string;
  @Prop() tooltipText?: string;
  @Prop() valueTooltipText?: string;
  @Prop() fieldName!: string;

  parseError(item: ClusterParamValidation, customText?: string): string {
    let result = "";
    if (item && item.$invalid) {
      if (item.required === false) {
        result = "please, provide a value";
      }
      if (item.minValue === false) {
        result = `minimum value for this field is ${item.$params.minValue.min}`;
      }
      if (item.maxValue === false) {
        result = `maximum value for this field is ${item.$params.maxValue.max}`;
      }
      if (customText) {
        result += ", " + customText;
      }
    }
    return result;
  }
}
