















import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class TextLine extends Vue {
  @Prop({ default: "" }) model!: string | number;
  @Prop({ default: "" }) label!: string;
  @Prop() tooltipText?: string;
  @Prop() valueTooltipText?: string;
  @Prop() fieldName!: string;

  get position() {
      return window.screen.width < 550 ? 'left' : 'top';
  }
}
