









import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class Tooltip extends Vue {
  @Prop({ default: "" }) tooltipText!: string;
  @Prop({ default: "top" }) position!: string;
  get screenSize() {
    return window.screen.width < 800 ? 'top' : this.position

  }
}
