export default class ClusterResources {
    totalMem: number;
    overheadMemory: number;
    availableCores: number;
    availableMemory: number;
    numberOfExecutorsPerNode: number;
    totalMemPerExecutor: number;
    constructor(totalMem = 0, overheadMemory = 0, availableCores = 0, availableMemory = 0, numberOfExecutorsPerNode = 0, totalMemPerExecutor = 0) {
        this.totalMem = totalMem;
        this.overheadMemory = overheadMemory;
        this.availableCores = availableCores;
        this.availableMemory = availableMemory;
        this.numberOfExecutorsPerNode = numberOfExecutorsPerNode;
        this.totalMemPerExecutor = totalMemPerExecutor;
    }
};
