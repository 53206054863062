



































import { Component, Prop, Vue } from "vue-property-decorator";
import { Validation, validationMixin } from "vuelidate";

@Component({
  mixins: [validationMixin],
})
export default class Form extends Vue {
  @Prop() formConfig!: Validation;
  @Prop() formData?: any;
  @Prop() heading!: string;
  @Prop() headingTooltipText?: string;
  @Prop({default: 'top'}) headingTooltipPosition?: string;
  @Prop({ default: false }) canCopy!: boolean;
  @Prop() copyToBuffer?: Function;
}
