export const calculateMiddleColor = (score: number) => {
    const color1 = "e8ffe4",
        color2 = "ffe4e4";
    const hex = (color: number) => {
        const colorString = color.toString(16);
        return colorString.length === 1 ? `0${colorString}` : colorString;
    };

    const r = Math.ceil(
        parseInt(color2.substring(0, 2), 16) * score +
        parseInt(color1.substring(0, 2), 16) * (1 - score)
    );
    const g = Math.ceil(
        parseInt(color2.substring(2, 4), 16) * score +
        parseInt(color1.substring(2, 4), 16) * (1 - score)
    );
    const b = Math.ceil(
        parseInt(color2.substring(4, 6), 16) * score +
        parseInt(color1.substring(4, 6), 16) * (1 - score)
    );

    return hex(r) + hex(g) + hex(b);
}