


















































































import { Component, Vue, Watch } from "vue-property-decorator";
//validation
import { required, minValue, maxValue } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
// models
import ClusterParams from "./models/ClusterParams";
import ClusterResources from "./models/ClusterResources";
import SparkConfig, {
  SparkConfigMap,
  UnitConfigMap,
} from "./models/SparkConfig";
import UnusedResources from "./models/UnusedResources";
//utils
import Calculations from "./utils/calculations";
import { copyToClipboard } from "./utils/copy";
import {
  additionalParamsList,
  clusterSizeParamsList,
  clusterResourcesList,
  unusedResourcesList,
  sparkConfigList,
  recommendedConfiguration,
  additionalParamsTooltip,
  recommendationHeadingTooltip,
} from "./utils/forms-config";

import { calculateMiddleColor } from "./utils/middle-color";
import BuyMeACoffee from "./components/BuyMeACoffee.vue";
import FieldModel from './models/FieldModel';

const validations = {
  clusterParams: {
    numberOfNodes: {
      required,
      minValue: minValue(1),
    },
    numberOfCores: {
      required,
      minValue: minValue(3),
    },
    memoryPerNode: {
      required,
      minValue: minValue(2),
    },
    parallelismPerCore: {
      required,
      minValue: minValue(1),
    },
    memoryOverhead: {
      required,
      maxValue: maxValue(50),
    },
  },
  sparkConfig: {
    defaultParallelism: {},
    executorMemory: {},
    executorInstances: {},
    driverCores: {},
    executorCores: {
      required,
      minValue: minValue(1),
      maxValue: maxValue(100),
    },
    driverMemory: {},
    driverMaxResultSize: {},
    driverMemoryOverhead: {},
    executorMemoryOverhead: {},
    dynamicAllocation: {},
    adaptiveQuery: {},
  },
};

@Component({
  mixins: [validationMixin],
  validations,
  components: {
    BuyMeACoffee,
  },
})
export default class App extends Vue {
  private clusterParams: ClusterParams = new ClusterParams();
  private clusterResources: ClusterResources = new ClusterResources();
  private sparkConfig: SparkConfig = new SparkConfig();
  private unusedResources: UnusedResources = new UnusedResources();
  public formattedCommand = "";
  private score = 0;
  public sparkSubmitActive = true;
  // get sparkSubmitActive() {
  //   return this._sparkSubmitActive;
  // }
  // configs - refactor may be needed
  additionalParamsList = additionalParamsList;
  additionalParamsTooltip = additionalParamsTooltip;

  clusterSizeParamsList = clusterSizeParamsList;
  clusterResourcesList = clusterResourcesList;
  unusedResourcesList = unusedResourcesList;
  sparkConfigList = sparkConfigList;
  recommendedConfiguration = recommendedConfiguration;
  recommendationHeadingTooltip = recommendationHeadingTooltip;

  @Watch("clusterParams.numberOfCores")
  onNumberOfCoresChange(value: number) {
    // update validation for executorCores maxValue according to numberOfCores
    // check initialization
    validations.sparkConfig.executorCores.maxValue = maxValue(value - 1);
  }

  get isInvalid() {
    return this.$v.clusterParams.$invalid || this.$v.sparkConfig.$invalid;
  }

  mounted() {
    this.calculate();
    // check
    validations.sparkConfig.executorCores.maxValue = maxValue(
      this.clusterParams.numberOfCores - 1
    );
  }

  get numberOfCores() {
    return this.clusterParams.numberOfCores;
  }

  calculate(): void {
    const CALC = new Calculations(
      this.clusterParams,
      this.clusterResources,
      this.sparkConfig,
      this.unusedResources
    );
    if (!this.isInvalid) {
      const {
        clusterResources,
        clusterParams,
        sparkConfig,
        unusedResources,
      } = CALC.calculate();
      this.sparkConfig = sparkConfig;
      this.clusterResources = clusterResources;
      this.clusterParams = clusterParams;
      this.unusedResources = unusedResources;
      this.formattedCommand = CALC.getFormattedCommand();
    }
    this.score = CALC.calculateScore();
  }

  get calcColor() {
    return calculateMiddleColor(this.score);
  }

  copyCommand() {
    copyToClipboard(this.formattedCommand);
  }

  toggleCommand(param: 'amazon' | 'spark-submit') {
    const CALC = new Calculations(
      this.clusterParams,
      this.clusterResources,
      this.sparkConfig,
      this.unusedResources
    );
    this.sparkSubmitActive = !this.sparkSubmitActive;
    if (param == 'amazon') {
      this.formattedCommand = CALC.getFormattedCommandAmazon();
    } else {
      this.formattedCommand = CALC.getFormattedCommand();
    }
  }

  copyConfig(obj: any) {
    let resultString = "";
    Object.entries(obj).forEach((entry) => {
      resultString += `${SparkConfigMap[entry[0]]}${"\t"}${
        entry[1] + UnitConfigMap[entry[0]]
      }${"\n"}`;
    });
    copyToClipboard(resultString);
  }

  copyRecommendedConfig(obj: FieldModel[]) {
    let resultString = "";
    obj.forEach((item) => {
      resultString += `${item.fieldName}${"\t"}${item.value}${"\n"}`;
    });
    copyToClipboard(resultString);
  }
}
