import Vue from 'vue';
import Vuelidate from 'vuelidate';
// global-components declaration
import NumberInput from "@/components/NumberInput.vue";
import Tooltip from '@/components/Tooltip.vue';
import TextLine from '@/components/TextLine.vue';
import Form from '@/components/Form.vue';
import Footer from '@/components/Footer.vue';


import App from './App.vue';

Vue.use(Vuelidate);
Vue.component('NumberInput', NumberInput);
Vue.component('Tooltip', Tooltip);
Vue.component('TextLine', TextLine);
Vue.component('Form', Form);
Vue.component('Footer', Footer);


Vue.config.productionTip = false
// Vue.component('ValidationProvider', ValidationProvider);

new Vue({
  render: h => h(App),
  validations: {}
}).$mount('#app')
