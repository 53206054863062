import { Validation } from 'vuelidate';

export default class ClusterParams {
  numberOfNodes: number;
  numberOfCores: number;
  memoryPerNode: number;
  parallelismPerCore: number;
  memoryOverhead: number;

  constructor(
    numberOfNodes = 5,
    numberOfCores = 16,
    memoryPerNode = 32,
    parallelismPerCore = 2,
    memoryOverhead = 10,
  ) {
    this.numberOfNodes = numberOfNodes;
    this.numberOfCores = numberOfCores;
    this.memoryPerNode = memoryPerNode;
    this.parallelismPerCore = parallelismPerCore;
    this.memoryOverhead = memoryOverhead;
  }
}
export interface ClusterParamValidation extends Validation {
  required?: boolean;
  minValue?: boolean;
  maxValue?: boolean;
}
